import React from 'react'


/* const style = { margin: "1rem", padding: "0.5rem", fontSize: '30px', color: 'rgb(80, 140, 155)', textAlign: 'center', backgroundColor: 'rgb(238, 238, 238)'}; */


export function Footer() {
    const currentYear = new Date().getFullYear()
    return (
    <footer className='Footer'>
    { `PORTFOLIO ${currentYear}`}
    </footer>
    )
    }
