import React from 'react';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import MeetPage from './meetForm'; 
import HomePage from './home';
import PortfolioPage from './portfolio';

const style = { margin: "1rem", padding: "0.5rem", fontSize: '30px', color: 'rgb(80, 140, 155)', marginbuttom: '10px'};


export default function Nav() {
  return (
    <BrowserRouter>
      <nav>
        <Link style={style} to="/">Home</Link>&nbsp;
        <Link style={style} to="/portfolio">Portfolio</Link>&nbsp; 
        <Link style={style} to="/meet">Meet me</Link>&nbsp; 
      </nav>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/portfolio" element={<PortfolioPage />} /> 
        <Route path="/meet" element={<MeetPage />} /> 
      </Routes>
    </BrowserRouter>
  );
}