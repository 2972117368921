import React from 'react'
import './App.css';
import Nav from './component/nav';
import { Footer } from './component/footer';

function App() {
  return (
    <div>
      <Nav />
      <Footer />
    </div>
  );
}

export default App;
