import React from 'react';

const HomePage = () => {
  return (
    <div className='main'>
      <h1>Welcome to my Portfolio</h1>
      <img src='/img/main.jpg' alt='main_picture' />
      
      <div className='about'>
        <h3>About me</h3>
        <p id='about'>Hi! My name is Kseniia. I'm a Full Stack Developer with a passion for creating dynamic and responsive web applications. With expertise in front-end technologies, I enjoy building solutions that are efficient, user-friendly, and scalable. Welcome to my portfolio, where you can explore my latest projects and learn more about my work.</p>
        <img src='/img/me.jpg' alt='main_picture' />
      </div>
      
    </div>
  );
};

export default HomePage;