import React from 'react';

const PortfolioPage = () => {
  return (
    <div>
      <h1>RECENT PROJECTS</h1>
      <div className='project'>
        <div className='project-content'>
          <h3>Pizza App</h3>
          <p>●    Created a pizza order form with input validation and error handling.</p>
          <p>●    Implemented a real-time order list with filtering options using React and Redux.</p>
          <p>●    Incorporated loading and error messages to enhance user experience.</p>
          <p>Full Stack Developer - React | Redux | Node</p>
        </div>
        <img src='/img/pizzaApp.png' alt='pizza' className="project-image" />
      </div>

      <div className='project'>
        <div className='project-content'>
          <h3>Coordinates Grid Game</h3>
          <p>●    Developed a dynamic grid game for navigating coordinates, tracking movements and email submissions.</p>
          <p>●    Implemented directional moves, email validation, and server interaction for game state persistence.</p>
          <p>Full Stack Developer - React | Axios | Testing Library</p>
        </div>
        <img src='/img/grid.png' alt='grid game' className="project-image" />
      </div>
    </div>
  );
};

export default PortfolioPage;