import React, { useState } from "react";
import axios from "axios";

const URL = 'https://reqres.in/api/users';

const MeetPage = () => {
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [email, setEmail] = useState('');
    const [success, setSuccess] = useState('');
    const [failure, setFailure] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        const payload = { fname, lname, email };
        console.log(payload);
        axios
            .post(URL, payload)
            .then((res) => {
                setSuccess(res.data);
                setFailure("");
                console.log(res.data);
            })
            .catch((err) => {
                console.error(err);
                setFailure(err.response?.data || "An error occurred");
                setSuccess("");
            });
    };

    return (
        <div className="contact">
            <div>
            <div>
                <h3>Contact information:</h3>
                <p>Kseniia Kondrat</p>
                <p>Phone: 346-247-8001</p>
                <p>Email: kseniiakondrat@gmail.com</p>
            </div>
            <p>
                If you would like to schedule a 15-minute video call with me, please,
                click on the "Appointment" button below.
            </p>
            <a
                href="https://calendly.com/oootlichno/15min"
                target="_blank"
                rel="noopener noreferrer"
                className="appointment-button"
            >
                Appointment
            </a>
            </div>
           {/*  <div className="form">
                <form onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="fname">First name:</label>
                        <br />
                        <input
                            id="fname"
                            type="text"
                            value={fname}
                            onChange={e => setFname(e.target.value)}
                            placeholder="Type first name"
                            minLength={3}
                        />
                    </div>

                    <div>
                        <label htmlFor="lname">Last name:</label>
                        <br />
                        <input
                            id="lname"
                            type="text"
                            value={lname}
                            onChange={e => setLname(e.target.value)}
                            placeholder="Type last name"
                            minLength={3}
                        />
                    </div>

                    <div>
                        <label htmlFor="email">E-mail:</label>
                        <br />
                        <input
                            id="email"
                            type="email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            placeholder="Type email"
                            minLength={5}
                        />
                    </div>

                    <div>
                        <button type="submit">Request an appointment</button>
                    </div>
                </form>
            </div> */}
        </div>
    );
};

export default MeetPage;